<template>
<section class="pt-8 pt-md-5 pb-8 pb-md-14">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1 class="mb-0 fw-bold">Dashboard</h1>
                <p class="mb-6 text-muted">Request statistics for your usage</p>
                <div class="row">
                    <div class="col-4">
                        <select class="form-select form-select-xs" name="token_id" v-model="token_id">
                            <option :value="0">By all tokens</option>
                            <option
                                :value="item.id"
                                v-for="item in data.tokens"
                                :key="item.id"
                                >{{ item.name }}</option>
                        </select>
                    </div>
                    <div class="col-4">
                        <select class="form-select form-select-xs" name="daterange" v-model="daterange">
                            <option disabled>Date range:</option>
                            <option
                                :value="item.ident"
                                v-for="item in data.dateranges"
                                :key="item.ident"
                                v-if="displayDaterange(item.ident)"
                                >{{ item.name }}</option>
                        </select>
                    </div>
                    <div class="col-4">
                        <select class="form-select form-select-xs" name="group_by" v-model="group_by">
                            <option disabled>Group by:</option>
                            <option
                                :value="item.ident"
                                v-for="item in data.groups"
                                :key="item.ident"
                                v-if="displayGroup(item.ident)"
                                >{{ item.name }}</option>
                        </select>
                    </div>
                </div>
                <highcharts :options="chartOptions"></highcharts>
                <div class="table-responsive">
                    <vuetable
                        ref="vuetable"
                        :api-mode="false"
                        :data="tableData"
                        class="ui celled table unstackable table-scroll"
                        wrapper-class="vuetable-wrapper ui basic segment"
                        table-wrapper=".vuetable-wrapper"
                        :fields="fields"
                        @vuetable:pagination-data="onPaginationData"
                        >
                        <template slot="v1_cnt" slot-scope="props">{{ findSum(props.rowData, "v1") }}</template>
                        <template slot="v1_user" slot-scope="props">{{ findSum(props.rowData, "v1_user") }}</template>
                        <template slot="v1_location" slot-scope="props">{{ findSum(props.rowData, "v1_location") }}</template>
                        <template slot="v1_hashtag" slot-scope="props">{{ findSum(props.rowData, "v1_hashtag") }}</template>
                        <template slot="v1_media" slot-scope="props">{{ findSum(props.rowData, "v1_media") }}</template>
                    </vuetable>
                    <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import Vuetable from "vuetable-2/src/components/Vuetable.vue";
import VuetablePagination from "./VuetablePagination";
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import { toHour } from "utils/date";

export default {
    components: {
        Vuetable,
        VuetablePagination,
        highcharts: Chart
    },
    watch: {
        group_by(val) {
            this.setFilter("group_by", val);
        },
        daterange(val) {
            this.setFilter("daterange", val);
        },
        token_id(val) {
            this.setFilter("token_id", val);
        }
    },
    created() {
        this.$root.Data.get().then(resp => {
            this.data = resp.body;
        });
        this.filter();
    },
    data() {
        let filterData = window.getFilter("dash"),
            group_by = filterData.group_by || "g1_hours",
            daterange = filterData.daterange || "",
            token_id =
            filterData.token_id === undefined ? 0 : filterData.token_id;
        filterData.group_by = group_by;
        filterData.daterange = daterange;
        return {
            fields: [
                { name: "datetime_ts", title: "Date", callback: toHour(this.$root.lang) },
                { name: "__slot:v1_cnt", title: "/v1" },
                { name: "__slot:v1_user", title: "/v1/user" },
                { name: "__slot:v1_hashtag", title: "/v1/hashtag" },
                { name: "__slot:v1_media", title: "/v1/media" },
            ],
            tableData: {},
            data: {},
            group_by,
            daterange,
            token_id,
            filterData,
            chartOptions: {
                chart: {
                    type: "line",
                    zoomType: "x",
                    panning: true,
                    panKey: "shift",
                    scrollablePlotArea: {
                        minWidth: 600
                    }
                },
                title: {
                    text: "" // Statistics
                },
                xAxis: {
                    categories: []
                },
                yAxis: {
                    // min: 0,
                    title: {
                        text: "Requests"
                    }
                },
                plotOptions: {
                    line: {
                        dataLabels: {
                            enabled: true
                        },
                        enableMouseTracking: false
                    }
                },
                series: []
            }
        };
    },
    methods: {
        displayDaterange(daterangeId) {
            if (parseInt(daterangeId.slice(1)) > 3) {
                return !this.group_by || parseInt(this.group_by.slice(1)) > 1;
            }
            return true;
        },
        displayGroup(groupId) {
            if (groupId == "g1_hours") {
                return !this.daterange || parseInt(this.daterange.slice(1)) < 4;
            }
            return true;
        },
        findSum(obj, prefix) {
            let sum = 0;
            for (let property in obj) {
                if (property.startsWith(prefix)) {
                    sum += obj[property];
                }
            }
            return sum;
        },
        setFilter(key, val) {
            this.filterData[key] = val;
            window.setFilter("dash", this.filterData);
            this.filter();
        },
        filter(data) {
            let params = Object.assign(data || {}, this.filterData);
            if (!this.displayDaterange(params["group_by"]) || !this.displayGroup(params["daterange"])) {
                console.warning("Wrong filter", params)
                return;
            }
            this.$root.Stats.get(params).then(resp => {
                this.tableData = resp.body.table;
                let chartData = resp.body.chart;
                setTimeout(() => {
                    this.chartOptions.series = chartData.series;
                    this.chartOptions.xAxis.categories = chartData.x_axis_categories.map(
                        toHour(this.$root.lang)
                    );
                    // build Username -> yAxis map
                    let item,
                        maxFollowers = 0,
                        currentFollowers = 0,
                        seriesMap = {};
                    for (item of chartData.series) {
                        seriesMap[item.name] = item.data;
                        currentFollowers = Math.max.apply(null, item.data);
                        if (currentFollowers > maxFollowers)
                            maxFollowers = currentFollowers;
                    }
                    // this.chartOptions.annotations = resp.body.annotations
                    //     .filter(item => {
                    //         return (
                    //             !item.token_id ||
                    //             seriesMap[item.token__username]
                    //         );
                    //     })
                    //     .map(item => {
                    //         let xCategory = chartData.x_axis_categories.indexOf(
                    //                 item.created_at_fmt
                    //             ),
                    //             yFollowers = seriesMap[item.token__username],
                    //             labelOptions = {};
                    //         if (item.token_id) {
                    //             labelOptions = {
                    //                 shape: "connector",
                    //                 align: "right",
                    //                 justify: false,
                    //                 crop: true,
                    //                 style: {
                    //                     fontSize: "0.8em",
                    //                     textOutline: "1px white",
                    //                     color: item.token__color
                    //                 }
                    //             };
                    //         }
                    //         return {
                    //             labelOptions,
                    //             labels: [
                    //                 {
                    //                     point: {
                    //                         xAxis: 0,
                    //                         yAxis: 0,
                    //                         x: xCategory,
                    //                         y: yFollowers
                    //                             ? yFollowers[xCategory]
                    //                             : maxFollowers
                    //                     },
                    //                     text: item.text
                    //                 }
                    //             ]
                    //         };
                    //     });
                });
            });
        },
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
        },
        onChangePage(page) {
            this.filter({ page });
        }
    }
};
</script>

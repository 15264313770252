<template>
<section class="pt-8 pt-md-5 pb-8 pb-md-14">
    <div class="container">
        <Spinner v-if="!loaded" />
        <div class="row" v-else>
            <div class="col-12">
                <h1 class="mb-0 fw-bold">Top up your balance</h1>
                <p class="mb-6 text-muted">On this page you can make a payment in a convenient way</p>
                <div class="table-responsive">
                    <h3>Worldwide</h3>
                    <table class="table table-striped">
                        <tbody>
                            <tr v-for="system in paySystems" v-if="system.group == 'ww'">
                                <td><input type="radio" v-model="form.system" :value="system" name="paysystem" :id="`p${system.id}`"></td>
                                <td>
                                    <label :for="`p${system.id}`" class="text-nowrap">{{ system.description }}</label>
                                    <div class="text-success" v-if="system.client_fee_percent < 0">
                                        We give {{ -system.client_fee_percent }}% when using this payment method
                                    </div>
                                    <div class="text-danger" v-else-if="system.client_fee_percent > 0">
                                        Fee {{ system.client_fee_percent }}% when using this payment method
                                    </div>
                                </td>
                                <td><label :for="`p${system.id}`">{{ system.currency__code }}</label></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="table-responsive">
                    <h3>Russian Federation</h3>
                    <table class="table table-striped">
                        <tbody>
                            <tr v-for="system in paySystems" v-if="system.group == 'ru'">
                                <td><input type="radio" v-model="form.system" :value="system" name="paysystem" :id="`p${system.id}`"></td>
                                <td>
                                    <label :for="`p${system.id}`" class="text-nowrap">{{ system.description }}</label>
                                    <div class="text-success" v-if="system.client_fee_percent < 0">
                                        We give {{ -system.client_fee_percent }}% when using this payment method
                                    </div>
                                    <div class="text-danger" v-else-if="system.client_fee_percent > 0">
                                        Fee {{ system.client_fee_percent }}% when using this payment method
                                    </div>
                                </td>
                                <td><label :for="`p${system.id}`">{{ system.currency__code }}</label></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="table-responsive">
                    <h3>Web3</h3>
                    <small class="text-secondary">These addresses are tied exclusively to your account, you can deposit with any amount of USDT</small>
                    <table class="table table-striped">
                        <tbody>
                            <tr v-for="system in paySystems" v-if="system.group == 'cc'" :init="initQR(system)">
                                <td colspan="2">
                                    <label :for="`p${system.id}`" class="text-nowrap">{{ system.description }}</label>
                                    <div class="text-success" v-if="system.client_fee_percent < 0">
                                        We give {{ -system.client_fee_percent }}% when using this payment method
                                    </div>
                                    <div class="text-danger" v-else-if="system.client_fee_percent > 0">
                                        Fee {{ system.client_fee_percent }}% when using this payment method
                                    </div>
                                    <hr/>
                                    <pre>
                                        <code>{{ system.address }}</code>
                                    </pre>
                                </td>
                                <td>
                                    <div>
                                        <img :src="system.qr" :alt="`QR for ${system.name}`"/>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <form class="mb-6" @submit.prevent="pay" v-show="form.system.id">
                    <div class="form-group">
                        <label class="form-label" for="amount">Amount</label>
                        <div class="input-group mb-3">
                            <span class="input-group-text" id="basic-addon1">{{ form.system.currency__symbol }}</span>
                            <input type="number" :min="minAmount" class="form-control" id="amount" v-model="form.amount"
                                   placeholder="Enter the amount" aria-describedby="basic-addon1" /><br>
                            <div clear="both" />
                        </div>
                        <small class="form-text text-danger" v-for="error in errors.amount">{{ error }}</small>
                    </div>
                    <button class="btn w-100 btn-primary" type="submit" :disabled="disabled">
                        Pay
                    </button>
                </form>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import Spinner from "../Spinner"
import QRCode from "qrcode"
export default {
    components: {
        Spinner
    },
    data() {
        return {
            loaded: false,
            disabled: false,
            paySystems: [],
            minAmount: 1,
            errors: {},
            form: {amount: 100, system: {}},
        }
    },
    methods: {
        pay() {
            this.errors = {}
            this.disabled = true
            let amount = this.form.amount,
                system = this.form.system.id
            this.$root.Payment.save({amount, system}).then(resp => {
                var data = resp.body
                if (resp.body.errors) {
                    this.errors = resp.body.errors
                }
                if (data.redirect_url) {
                    window.location.href = resp.body.redirect_url
                } else if (data.sign) {
                    var form = document.createElement('FORM')
                    form.name = 'payeerForm'
                    form.method = 'POST'
                    form.action = 'https://payeer.com/merchant/'
                    var fields = {
                        m_shop: data.shop_id,
                        m_orderid: data.order_id,
                        m_amount: data.amount,
                        m_curr: data.currency_code,
                        m_desc: data.description,
                        m_sign: data.sign,
                        m_process: "send",
                        lang: "en"
                    }
                    for (let name in fields) {
                        let input = document.createElement('INPUT')
                        input.type = 'HIDDEN'
                        input.name = name
                        input.value = fields[name]
                        form.appendChild(input)
                    }
                    document.body.appendChild(form);
                    form.submit();
                }
            }).finally(() => {
                this.disabled = false
            })
        },
        fetch(data) {
            data = data || {}
            this.loaded = true // hack
            this.$root.PaymentSystem.get(data).then(resp => {
                this.paySystems = resp.body;
            }).finally(() => {
                this.loaded = true
            })
        },
        initQR(system) {
            QRCode.toDataURL(system.address).then(qr => {
                this.$set(system, "qr", qr)
            })
        }
    },
    created() {
        this.fetch()
    },
    watch: {
        'form.system'(val, old) {
            if (val) {
                this.minAmount = this.form.amount = val.currency__min_amount
            } else {
                this.minAmount = this.form.amount = 50
            }
        }
    }
}
</script>

<template>
<section class="pt-8 pt-md-11 pb-12">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-md">
                <h1 class="display-4 mb-8">{{ article.title }}</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-12" v-html="article.html_content"></div>
        </div>
    </div>
</section>
</template>

<script>
import Vue from 'vue';

export default {
    data() {
        return {
            article: {}
        }
    },
    created() {
        this.get(this.$route.path)
    },
    methods: {
        get(path) {
            let parts = path.split('/')
            let slug = parts[parts.length - 1]
            this.$root.WikiArticle.get({slug}).then(resp => {
                this.article = resp.body
                Vue.nextTick(() => {
                    this.$root.buildTitle(this.article.title)
                });
            })
        }
    },
    watch: {
        $route(to, from) {
            this.get(to.path)
        }
    }
}
</script>
